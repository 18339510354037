<template>
  <div v-if="hub.leaderboard !== undefined && hub.leaderboard.length > 0">
    <div class="lb-1">
      <v-col
        cols="12"
        md="12"
        class="top-1 ml-3 pb-0"
      >
        <div class="top-1">
          <v-img
            width="20"
            height="100%"
            :src="'/flags/' + hub.leaderboard[0].country + '.png'"
            class="user-flag ml-1 mr-1"
          >
          </v-img>

          <v-img
            width="22"
            height="22"
            :src="'/faceit/faceit' + hub.leaderboard[0].level + '.svg'"
            class="faceitlvlteam"
          ></v-img>
          <router-link
            class="player-link"
            :to="{ name: 'other-profile', params: { id: hub.leaderboard[0].id }}"
          >
            {{ hub.leaderboard[0].nickname }}
          </router-link>
          <v-card-text class="no-padding">
            <p
              class="points-badge"
            >
              {{ hub.leaderboard[0].points }} points
            </p>
            <br>
            <p
              class="points-badge"
            >
              {{ hub.leaderboard[0].played }} - matches
            </p>
            <p
              class="points-badge"
            >
              {{ Math.round(hub.leaderboard[0].won / hub.leaderboard[0].played * 100) }}% WR
            </p>
            <p
              class="points-badge-w"
            >
              {{ hub.leaderboard[0].won }}W
            </p>
            <p
              class="points-badge-l"
            >
              {{ hub.leaderboard[0].played - hub.leaderboard[0].won }}L
            </p>
          </v-card-text>
        </div>
      </v-col>
        <!-- <v-col
          cols="12"
          md="2"
          class="top-1 float-right "
        >
          <v-icon
            color="white"
            size="100%"
            class="mb-4"
          >
            {{ icons.mdiTrophy }}
          </v-icon> -->
      <!-- <div class="">
            <div class="">
              <v-avatar
                size="120"
                class="avatar-center "
              >
                <v-img
                  class="player-border"
                  :src="hub.leaderboard[0].avatar"
                ></v-img>
              </v-avatar>
            </div>
          </div> -->
      </v-col>
    </div>

    <v-list
      class="match-border event-padding"
      dense
    >
      <v-list-item class="text-left">
        <v-list-item-content>
          <v-list-item-title
            v-for="player, index in hub.leaderboard.slice(1)"
            :key="player.nickname"
            class="lb-list mt-2"
          >
            <v-img
              width="15"
              height="100%"
              :src="'/flags/' + player.country + '.png'"
              class="user-flag"
            ></v-img> <v-img
              width="21"
              height="21"
              :src="'/faceit/faceit' + player.level + '.svg'"
              class="faceitlvlteam"
            ></v-img>
            <router-link
              class="player-link"
              :to="{ name: 'other-profile', params: { id: player.id }}"
            >
              {{ player.nickname }}
              <div class="text-left stats-leaderboard">
                <!-- <p
                  class=" points-badge"
                >
                  {{ player.played }} Played
                </p>
                <p
                  class=" points-badge"
                >
                  {{ player.won }} Won
                </p>
                <p
                  class=" points-badge"
                >
                  win% {{ Math.round((player.won / player.played) * 100) }}
                </p> -->
                <p
                  class=" points-badge"
                >
                  {{ player.points }} points
                </p>
              </div>
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
  <div
    v-else
    class="progressbar-leaderboard d-flex align-center justify-center"
  >
    <v-progress-circular
      v-if="hub.leaderboardError === undefined && hub.leaderboard === undefined"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <span v-else-if="hub.leaderboard !== undefined && hub.leaderboard <= 0">
      {{ hub.name }} doesn't have a leaderboard yet!
    </span>
    <span v-else>
      {{ hub.leaderboardError }}
    </span>
  </div>
</template>

<script>
import { mdiTrophy } from '@mdi/js'

export default {
  props: ['hub'],
  created() {},
  setup() {
    return {
      icons: {
        mdiTrophy,
      },
    }
  },
}
</script>

<style scoped>
.progressbar-leaderboard {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #1a1a20;
  min-height: 75px;
}
</style>
