<template>
  <div>
    <v-row>
      <v-col
        v-if="tournaments"
        cols="12"
        md="12"
      >
        <template>
          <v-tabs>
            <v-tab
              v-for="tournament in tournaments"
              :key="tournament.id"
              class="text-wrap"
            >
              {{ tournament.name }}
            </v-tab>
            <v-tab-item
              v-for="tournament in tournaments"
              :key="tournament.id"
            >
              <v-tabs>
                <v-tab v-if="tournament.past_matches.length > 0">
                  Past matches
                </v-tab>
                <v-tab v-if="!tournament.finished && tournament.upcoming_matches.length > 0">
                  Upcoming matches
                </v-tab>
                <v-tab v-if="!tournament.finished && tournament.ongoing_matches.length > 0">
                  Live matches
                </v-tab>
                <v-tab v-if="!(tournament.past_matches.length > 0 || tournament.upcoming_matches.length > 0 || tournament.ongoing_matches.length > 0)">
                  General information
                </v-tab>
                <v-tab :to="{ name: 'tournament-open', params: { id: tournament.id } }">
                  Event page
                </v-tab>
                <v-tab-item v-if="tournament.past_matches.length > 0">
                  <div class="m-slide-group">
                    <v-tabs
                      show-arrows
                      height="60px"
                    >
                      <m-tab
                        v-for="match, index in tournament.past_matches"
                        :key="index"
                      >
                        <div class="match-result-frame inline-block border-right">
                          <div class="text-left">
                            <v-img
                              v-if="match.faction1_country"
                              width="35"
                              height="100%"
                              :src="'flags/' + match.faction1_country.toLowerCase() + '.png'"
                              class="team-result-flag"
                            ></v-img>
                            <span class="inline-block text-left">{{ match.faction1_name }}</span>
                            <div
                              v-if="match.winner != null"
                              class="text-center block mr-2"
                              :class="match.winner == 'faction1' ? 'koefs-badge' : 'koefs-badge-l'"
                            >
                              {{ match.winner == 'faction1' ? 'W' : 'L' }}
                            </div>
                          </div>

                          <div class="text-left">
                            <v-img
                              v-if="match.faction2_country"
                              width="35"
                              height="100%"
                              :src="'flags/' + match.faction2_country.toLowerCase() + '.png'"
                              class="team-result-flag"
                            ></v-img>
                            <span class="inline-block text-left">{{ match.faction2_name }}</span>
                            <div
                              v-if="match.winner != null"
                              class="float-right text-center block mr-2"
                              :class="match.winner == 'faction2' ? 'koefs-badge' : 'koefs-badge-l'"
                            >
                              {{ match.winner == 'faction2' ? 'W' : 'L' }}
                            </div>
                          </div>

                          <div class="inline-block">
                            <div class="text-left inline-block time-badge">
                              <a
                                class="team-url"
                                target="_blank"
                                :href="match.faceit_url.replace('{lang}', 'en')"
                              >Match Page</a>
                            </div>
                          </div>
                        </div>
                      </m-tab>
                    </v-tabs>
                  </div>
                </v-tab-item>
                <v-tab-item v-if="!tournament.finished && tournament.upcoming_matches.length > 0">
                  <v-tabs
                    show-arrows
                    height="60px"
                  >
                    <m-tab
                      v-for="match, index in tournament.upcoming_matches"
                      :key="index"
                    >
                      <div class="match-result-frame inline-block border-right">
                        <div class="text-left">
                          <v-img
                            v-if="match.faction1_country"
                            width="35"
                            height="100%"
                            :src="'flags/' + match.faction1_country.toLowerCase() + '.png'"
                            class="team-result-flag"
                          ></v-img>
                          <span class="inline-block text-left">{{ match.faction1_name }}</span>
                          <div
                            v-if="match.winner != null"
                            class="text-center block mr-2"
                            :class="match.winner == 'faction1' ? 'koefs-badge' : 'koefs-badge-l'"
                          >
                            {{ match.winner == 'faction1' ? 'W' : 'L' }}
                          </div>
                        </div>

                        <div class="text-left">
                          <v-img
                            v-if="match.faction2_country"
                            width="35"
                            height="100%"
                            :src="'flags/' + match.faction2_country.toLowerCase() + '.png'"
                            class="team-result-flag"
                          ></v-img>
                          <span class="inline-block text-left">{{ match.faction2_name }}</span>
                          <div
                            v-if="match.winner != null"
                            class="float-right text-center block mr-2"
                            :class="match.winner == 'faction2' ? 'koefs-badge' : 'koefs-badge-l'"
                          >
                            {{ match.winner == 'faction2' ? 'W' : 'L' }}
                          </div>
                        </div>

                        <div class="inline-block">
                          <div class="text-left inline-block time-badge">
                            <a
                              class="team-url"
                              target="_blank"
                              :href="match.faceit_url.replace('{lang}', 'en')"
                            >Match Page</a>
                          </div>
                        </div>
                      </div>
                    </m-tab>
                  </v-tabs>
                </v-tab-item>
                <v-tab-item v-if="!tournament.finished && tournament.ongoing_matches.length > 0">
                  <v-tabs
                    show-arrows
                    height="100px"
                  >
                    <m-tab
                      v-for="match, index in tournament.ongoing_matches"
                      :key="index"
                    >
                      <div class="match-result-frame inline-block border-right">
                        <div class="text-left">
                          <v-img
                            v-if="match.faction1_country"
                            width="35"
                            height="100%"
                            :src="'flags/' + match.faction1_country.toLowerCase() + '.png'"
                            class="team-result-flag"
                          ></v-img>
                          <span class="inline-block text-left">{{ match.faction1_name }}</span>
                          <div
                            v-if="match.winner != null"
                            class="text-center block mr-2"
                            :class="match.winner == 'faction1' ? 'koefs-badge' : 'koefs-badge-l'"
                          >
                            {{ match.winner == 'faction1' ? 'W' : 'L' }}
                          </div>
                        </div>

                        <div class="text-left">
                          <v-img
                            v-if="match.faction2_country"
                            width="35"
                            height="100%"
                            :src="'flags/' + match.faction2_country.toLowerCase() + '.png'"
                            class="team-result-flag"
                          ></v-img>
                          <span class="inline-block text-left">{{ match.faction2_name }}</span>
                          <div
                            v-if="match.winner != null"
                            class="float-right text-center block mr-2"
                            :class="match.winner == 'faction2' ? 'koefs-badge' : 'koefs-badge-l'"
                          >
                            {{ match.winner == 'faction2' ? 'W' : 'L' }}
                          </div>
                        </div>

                        <div class="inline-block">
                          <div class="text-left inline-block time-badge">
                            <a
                              class="team-url"
                              target="_blank"
                              :href="match.faceit_url.replace('{lang}', 'en')"
                            >Match Page</a>
                          </div>
                        </div>
                      </div>
                    </m-tab>
                  </v-tabs>
                </v-tab-item>
                <v-tab-item v-if="!(tournament.past_matches.length > 0 || tournament.upcoming_matches.length > 0 || tournament.ongoing_matches.length > 0)">
                  <v-card>
                    <b>Event Signup starts:</b> {{ unixToFormattedTime(tournament.signup_start) }} <br>
                    <b>Event Signup end:</b> {{ unixToFormattedTime(tournament.signup_end) }} <br>
                    <b>Event start time</b> {{ unixToFormattedTime(tournament.start_time) }} <br>
                    <v-btn
                      color="faceit"
                      :href="tournament.url.replace('{lang}', 'en')"
                      target="_blank"
                      class="mt-6 mb-6"
                    >
                      <span class="">Register</span>
                    </v-btn>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-tab-item>
          </v-tabs>
        </template>
      </v-col>
    </v-row>
    <v-row
      style="width: 100%"
      class="mr-0 ml-0"
    >
      <v-col
        v-if="featured_article != null"
        md="5"
        sm="4"
        cols="12"
        class="pl-0 pr-0 "
      >
        <div class="section-title-home">
          Featured News
        </div>
        <v-card
          class="v-avatar-group mr-2"
          @click="$router.push({ name: 'article-open', params: { id: featured_article.id } })"
        >
          <v-img
            :src="featured_article.featuredImage"
            class="new-post no-br"
            gradient="to top,  rgba(20, 20, 23,.9), rgba(20, 20, 23,.33)"
          >
            <div class="mt-2 ml-4">
              <v-chip class="csgo">
                CS:GO
              </v-chip>
            </div>
            <div class="news-content-frame ">
              <v-card-title class="v-avatar-group">
                <a
                  class="news-link"
                  href="#"
                >      <v-img
                  width="35"
                  height="100%"
                  :src="'flags/' + featured_article.country + '.png'"
                  class="news-flag"
                ></v-img>{{ featured_article.title }}</a>
              </v-card-title>
              <v-card-text>
                {{ featured_article.description }}
              </v-card-text>
            </div>
          </v-img>

          <v-card class="author-card mt-10">
          </v-card>
        </v-card>
      </v-col>

      <v-col
        v-if="$store.getters['post/HasPageLoaded'](postPage)"
        md="4"
        sm="4"
        cols="12"
        class="pl-0 pr-0 "
      >
        <div class="mt-10"></div>
        <v-img
          v-for="post in $store.getters['post/GetPosts'](postPage).data.slice(1, 3)"
          :key="post.id + 'post'"
          gradient="to top,  rgba(20, 20, 23,.9), rgba(20, 20, 23,.33)"
          :src="post.featuredImage"
          height="255"
          class="mb-2"
          @click="$router.push({ name: 'article-open', params: { id: post.id } })"
        >
          <div class="mt-2 ml-4">
            <v-chip class="csgo">
              CS:GO
            </v-chip>
          </div>
          <div class="news-content-frame mb-4 v-avatar-group ">
            <div class="news-title mb-2">
              <v-img
                width="35"
                height="100%"
                :src="'flags/' + post.country + '.png'"
                class="news-flag"
              ></v-img>
              <a
                class="news-link news-title "
                href="#"
              >{{ post.title }}</a>
            </div>
          </div>
        </v-img>
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <div class="section-title-home">
          Featured streams
        </div>
        <LiveStreamList />
      </v-col>
      <!-- <v-col
        cols="12"
        md="3"
      >
        <div class="section-title-home">
          CEE Qualification list
        </div>
        <v-card class="mb-2">
          <template>
            <v-tabs>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiAccountOutline }}
                </v-icon>
                🇪🇪 CEE Quals
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiLockOutline }}
                </v-icon>
                🇱🇻  CEE Quals
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiAccessPoint }}
                </v-icon>
                🇱🇹 CEE Quals
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-timeline
                      dense
                      class="timeline-custom-dense timeline-custom-dots"
                    >
                      <v-timeline-item
                        small
                        color="warning"
                      >
                        <div class="d-flex justify-space-between align-center flex-wrap">
                          <h4 class="font-weight-semibold me-1">
                            CEE representives
                          </h4>
                          <small class="text-no-wrap">Waiting</small>
                        </div>
                        <p class="mb-0">
                          Waiting for 2 teams from Estonia
                        </p>
                      </v-timeline-item>
                    </v-timeline>
                    <v-timeline
                      dense
                      class="timeline-custom-dense timeline-custom-dots"
                    >
                      <v-timeline-item
                        small
                        color="info"
                      >
                        <div class="d-flex justify-space-between align-center flex-wrap">
                          <h4 class="font-weight-semibold me-1">
                            Qualifications
                          </h4>
                          <small class="text-no-wrap">In-progress</small>
                        </div>
                        <p class="mb-0">
                          🇪🇪 Levadia eLions got invited to the Finals
                          <br>
                          🇪🇪 SC e-Sports got invited to the Finals
                          <br>
                          🇪🇪 <span class="text-blue">Team Clowns</span> qualifies from <a href="https://www.faceit.com/en/championship/86604240-d603-4f1b-8a1a-893f5e1d6e59/CEE%20Estonia%20-%201%20kvalifikatsioon">Qualification #1</a>
                          <br>
                          🇪🇪 <span class="text-blue">faceitpolice</span> qualifies from <a href="https://www.faceit.com/en/championship/2b9d795c-211a-4c4b-9631-a804d1266e5b/CEE%20Estonia%20-%202%20kvalifikatsioon">Qualification #2</a>
                          <br>
                          🇪🇪 <span class="text-blue">pushinP</span> qualifies from <a href="https://www.faceit.com/en/championship/fcae434f-9aa4-41cf-87dd-a21769193ced/">Qualification #3</a>
                          <br>
                          🇪🇪 <span class="text-blue">lesgedi</span> qualifies from <a href="https://www.faceit.com/en/championship/a1b6c41d-49cc-4bd9-adfc-d88c1cc43987/CEE%20Estonia%20-%204%20kvalifikatsioon">Qualification #4</a>
                        </p>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-timeline
                      dense
                      class="timeline-custom-dense timeline-custom-dots"
                    >
                      <v-timeline-item
                        small
                        color="warning"
                      >
                        <div class="d-flex justify-space-between align-center flex-wrap">
                          <h4 class="font-weight-semibold me-1">
                            CEE representives
                          </h4>
                          <small class="text-no-wrap">Waiting</small>
                        </div>
                        <p class="mb-0">
                          Waiting for 2 teams from Latvia
                        </p>
                      </v-timeline-item>
                    </v-timeline>
                    <v-timeline
                      dense
                      class="timeline-custom-dense timeline-custom-dots"
                    >
                      <v-timeline-item
                        small
                        color="info"
                      >
                        <div class="d-flex justify-space-between align-center flex-wrap">
                          <h4 class="font-weight-semibold me-1">
                            Qualifications
                          </h4>
                          <small class="text-no-wrap">Waiting</small>
                        </div>
                        <p class="mb-0">
                          No information about qualifications
                        </p>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-timeline
                      dense
                      class="timeline-custom-dense timeline-custom-dots"
                    >
                      <v-timeline-item
                        small
                        color="warning"
                      >
                        <div class="d-flex justify-space-between align-center flex-wrap">
                          <h4 class="font-weight-semibold me-1">
                            CEE representives
                          </h4>
                          <small class="text-no-wrap">Waiting</small>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                    <v-timeline
                      dense
                      class="timeline-custom-dense timeline-custom-dots"
                    >
                      <v-timeline-item
                        small
                        color="info"
                      >
                        <div class="d-flex justify-space-between align-center flex-wrap">
                          <h4 class="font-weight-semibold me-1">
                            Qualifications
                          </h4>
                          <small class="text-no-wrap">In-progress</small>
                        </div>
                        <p class="mb-0">
                          🇱🇹 <span class="text-blue">Force Gaming</span> qualifies from <a href="https://www.faceit.com/en/championship/85160234-2940-4ebd-bcd6-6a1bf42251ff/GG%20Arena%20Open%20Cup%20Qualifier%201/results">Qualification #1</a>
                          <br>
                          🇱🇹 <span class="text-blue">GOATED</span> qualifies from <a href="https://www.faceit.com/en/championship/85160234-2940-4ebd-bcd6-6a1bf42251ff/GG%20Arena%20Open%20Cup%20Qualifier%201/results">Qualification #1</a>
                          <br>
                          🇱🇹 <span class="text-blue">LastWind</span> qualifies from <a href="https://www.faceit.com/en/championship/792fe37a-f71d-405e-9832-9da7b86689f6/GG%20Arena%20Open%20Cup%20Qualifier%202">Qualification #2</a>
                          <br>
                          🇱🇹 <span class="text-blue">AFTER PARTY</span> qualifies from <a href="https://www.faceit.com/en/championship/792fe37a-f71d-405e-9832-9da7b86689f6/GG%20Arena%20Open%20Cup%20Qualifier%202">Qualification #2</a>
                        </p>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </template>
        </v-card>
        <LiveStreamList />
      </v-col> -->
    </v-row>

    <v-row>
      <v-col
        md="5"
        cols="12"
      >
        <!-- <div class="section-title-home">
          Featured events
        </div>
        <v-card
          v-if="$store.getters['tournament/GetFirstTournament'] !== undefined"
          class="event-border"
        >
          <v-img
            class="white--text align-center"
            src="/header/csgo-series-dark2.png"
            height="100%"
          >
            <v-card-title class="pt-4 px-0 pl-5 ">
              <a
                class="event-link"
                href="#"
              >{{ $store.getters['tournament/GetFirstTournament'].name }}</a>
            </v-card-title>
            <div class="pl-4 pr-4 registered-teams">
              <div class="d-flex justify-space-between align-center">
                <span class="font-weight-medium registration-badge">
                  {{ $store.getters['tournament/GetFirstTournament'].team_count }} / {{ $store.getters['tournament/GetFirstTournament'].slots }} teams registered
                </span>

                <div
                  class="v-avatar-group"
                  :class="rootThemeClasses"
                >
                  <v-btn
                    color="mainbtn"
                    class="ml-1 mr-1"
                    @click="$router.push('/tournament')"
                  >
                    <span class="clr-black">Register</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-img>
        </v-card> -->
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
      </v-col>
      <!-- <v-col
        cols="12"
        md="3 "
      >
        <div class="text-left">
          <v-icon
            size="1rem"
          >
            {{ icons.mdiFormatListNumbered }}
          </v-icon> BGL CS:GO Community Leaderboard
        </div>
        <v-card
          v-for="hub in $store.getters['faceit/GetHubs']"
          :key="hub.name + hub.key + 'leaderboard'"
        >
          <division-leaderboard
            :hub="hub"
          >
          </division-leaderboard>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4967670342965100"
     crossorigin="anonymous"></script>
<script>
// eslint-disable-next-line object-curly-newline

import { unixToFormattedTime } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiAlertOutline,
  mdiBrightness1,
  mdiTrophy,
  mdiTwitch,
  mdiChevronUp,
  mdiChevronDown,
  mdiTwitter,
  mdiNewspaperVariant,
  mdiNewspaperVariantOutline,
  mdiHeart,
  mdiShareVariant,
  mdiFormatListNumbered,
  mdiAccountGroup,
  mdiTag,
} from '@mdi/js'

// demos
import useVuetify from '@core/utils/vuetify'
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import DivisionLeaderboard from '@/views/components/league/DivisionLeaderboard.vue'
import LiveStreamList from '@/views/components/social/LiveStreamList.vue'
import axiosClient from '@/plugins/axios'

export default {
  components: {
    StatisticsCardWithImages,
    DivisionLeaderboard,
    LiveStreamList,
  },
  data() {
    return {
      currentTab: null,
      article: null,
      selectedId: this.$route.params.id,
      postPage: 1,
      teams: [
        {
          name: 'wortex',
          country: 'ee',
          wins: '0',
          losses: '0',
          tournament_name: 'ESEA IM S40',
          tournament_url: 'https://play.esea.net/league/standings?divisionId=3444#team8774410',

          matches: [],
        },
      ],
    }
  },
  computed: {
    featured_article() {
      return this.$store.getters['post/HasPageLoaded'](1) ? this.$store.getters['post/GetPosts'](1)?.data[0] : null
    },
    tournaments() {
      return this.$store.getters['tournament/HasPageLoaded'](1)
        ? this.$store.getters['tournament/GetTournaments'](1)?.data.reverse()
        : null
    },
  },
  watch: {
    postPage(val) {
      if (!this.$store.getters['post/HasPageLoaded'](val)) this.$store.dispatch('post/loadPosts', { page: val })
    },
  },
  async mounted() {
    if (this.selectedId) {
      const response = await axiosClient.get(`${this.$API}/article/${this.selectedId}`)
      if (!response.data.title) return
      this.article = response.data
    }
  },
  setup() {
    const ratingsOptions = {
      statTitle: 'Players searching for team',
      statistics: '149',
    }

    const isCardDetailsVisible = false
    const { rootThemeClasses } = useVuetify()

    return {
      rootThemeClasses,
      isCardDetailsVisible,
      ratingsOptions,
      unixToFormattedTime,
      icons: {
        mdiAlertCircleOutline,
        mdiCheck,
        mdiAlertOutline,
        mdiBrightness1,
        mdiTrophy,
        mdiTwitch,
        mdiChevronUp,
        mdiChevronDown,
        mdiHeart,
        mdiShareVariant,
        mdiTwitter,
        mdiNewspaperVariant,
        mdiNewspaperVariantOutline,
        mdiFormatListNumbered,
        mdiTag,
        mdiAccountGroup,
      },
    }
  },
}
</script>
