<template>
  <v-list
    v-if="liveStreams != null && liveStreams.length > 0"
    class="match-border  event-padding"
    dense
  >
    <LiveStreamListItem
      v-for="stream in liveStreams"
      :key="stream.id"
      :stream="stream"
    />
  </v-list>
  <v-card
    v-else-if="liveStreams != null"
    height="246px"
    class="livestreams-list text-center"
  >
    No featured streams are live!<br>Contact us via Discord to get featured!
  </v-card>
  <v-card v-else>
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-card>
</template>

<script>
import LiveStreamListItem from '@/views/components/social/LiveStreamListItem.vue'

export default {
  components: {
    LiveStreamListItem,
  },
  computed: {
    liveStreams() {
      return this.$store.getters['social/GetPublicStreams']
    },
  },
}
</script>
<style scoped>
.progressbar-leaderboard {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #312d4b;
  min-height: 75px;
}
</style>
