<template>
  <v-list-item class="twitch-item">
    <v-list-item-content class="mb-0 pb-0 pt-0 mt-0">
      <v-list-item-title class="match-esea">
        <!-- <v-avatar
          size="35"
          class="avatar-center mr-2"
        >
          <v-img
            class="player-border-twitch"
            :src="thumbnail"
          ></v-img>
        </v-avatar> -->
        <p class="ml-1 mr-1 top-1 mb-0 v-avatar-group ">
          <a
            :href="'https://twitch.tv/' + stream.user_login"
            class="reg-url twitch-badge"
            target="_blank"
            :class="rootThemeClasses"
          >{{ stream.viewer_count }}
            <v-icon
              size="0.75rem mb-2"
              class="clr-blue"
            >
              {{ icons.mdiTwitch }}
            </v-icon>   {{ stream.user_name }} - <p class="twitch-game">Playing:   {{ stream.game_name }}</p></a>
        </p>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mdiTwitch, mdiAccountGroup } from '@mdi/js'

export default {
  props: ['stream'],
  computed: {
    thumbnail() {
      return this.stream.thumbnail_url.replace('{width}', '29').replace('{height}', '29')
    },
  },
  setup() {
    return {
      icons: {
        mdiTwitch,
        mdiAccountGroup,
      },
    }
  },
}
</script>
